$(function () {
    setHeaderScroll();
    hamburger();
    fancyBoxInit();
    // scrollRevealInit();
    slickSliderInit();
});

function scrolling() {
}

function resizing() {
}

$(document).on('scroll', scrolling);
$(window).on('resize', resizing);


