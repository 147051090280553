// https://github.com/kenwheeler/slick/

const slickSliderInit = () => {
    $("[data-slider-service]").slick({
        infinite: false,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    $("[data-slider-reference]").slick({
        infinite: false,
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    });

    const sliderName = document.querySelectorAll("[data-slider-name]");
    const sliderNames = [];

    sliderName.forEach((item) => {
        let name = item.dataset.sliderName;
        sliderNames.push(name);
    });

    $("[data-slider-slider]").slick({
        centerMode: true,
        centerPadding: "300px",
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        // autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        customPaging: function (slick, index) {
            return "<a>" + sliderNames[index] + "</a>";
        },
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    centerPadding: "200px",
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    centerPadding: "150px",
                },
            },
            {
                breakpoint: 768,
                settings: {
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    centerPadding: "0",
                },
            },
        ],
    });
};
